<template>
  <div class="relative top-0 left-0 right-0 bg-white shrink-0">
    <div class="max-w-screen mx-auto z-100 print:max-w-[720px]">
      <div class="flex justify-between w-full max-w-screen-xl mx-auto px-md">
        <div
          class="md:absolute flex items-center h-[62px] text-lg text-minilu-base"
        >
          <NuxtLink to="/checkout/basket">
            <div
              class="flex flex-row-reverse gap-1 cursor-pointer md:gap-2 place-items-center font-pero text-18"
            >
              <span
                class="anim__underline-link minilu-anim-link text-md md:text-lg"
              >
                {{ t('checkoutSubView.header.back') }}
              </span>
              <FaIcon icon-class="fas fa-chevron-left" />
            </div>
          </NuxtLink>
        </div>

        <div class="md:mx-auto md:ml-auto">
          <NuxtLink to="/">
            <img
              v-if="$languageCode === 'de'"
              src="~/assets/images/minilu/minilu-logo-de.svg"
              title="minilu logo"
              alt="minilu logo"
              class="sm:h-[32px] sm:w-[165px] sm:my-sm h-[16px] w-[83px] my-[23px]"
            />

            <img
              v-if="$languageCode === 'at'"
              src="~/assets/images/minilu/minilu-logo-at.svg"
              title="minilu logo"
              alt="minilu logo"
              class="h-[32px] w-[165px] my-sm"
            />

            <img
              v-if="$languageCode === 'nl'"
              src="~/assets/images/minilu/minilu-logo-nl.svg"
              title="minilu logo"
              alt="minilu logo"
              class="h-[32px] w-[165px] my-sm"
            />
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import FaIcon from '@/components/fa-icon.vue';

const { t } = useTrans();
</script>
