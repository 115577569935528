<template>
  <div
    class="relative w-full bg-minilu-base touch:md:h-[56px] lg:h-[56px] shrink-0"
  >
    <div
      :class="[
        'flex flex-col items-center max-w-screen-xl grid-cols-12 mx-auto text-white px-md',
        'touch:md:justify-between touch:md:flex-row touch:md:gap-md touch:md:h-full',
        'lg:justify-between lg:flex-row lg:gap-md lg:h-full',
      ]"
    >
      <div
        :class="[
          'flex items-center h-full whitespace-nowrap py-md',
          'touch:md:w-[400px] touch:md:py-0',
          'lg:w-[400px] lg:py-0',
        ]"
      >
        <BasicLink
          link="/checkout/basket"
          :icon-position="EPosition.LEFT"
          :label="t('checkoutSubView.header.back')"
          target="_self"
          icon="fas fa-chevron-left"
          class="text-md md:text-lg text-minilu-white"
        />
      </div>
      <div
        :class="[
          'flex flex-row items-center justify-center order-3',
          'touch:md:justify-start touch:md:order-2',
          'lg:justify-start lg:order-2',
        ]"
      >
        <img
          title="minilu logo"
          alt="minilu logo"
          src="~/assets/images/minilu/footer-minilu.svg"
          class="h-[100px] md:h-[72px] touch:md:absolute lg:absolute bottom-0"
          loading="lazy"
        />
        <div
          class="ml-[5px] touch:md:ml-[100px] lg:ml-[100px] font-pero text-[22px] whitespace-nowrap"
        >
          {{ t('footer.smallPrices') }}
        </div>
      </div>
      <div
        :class="[
          'flex items-center h-full justify-self-center order-2 pb-sm text-lg gap-sm',
          'touch:md:justify-end touch:md:order-3 touch:md:pb-0 touch:md:gap-md touch:md:w-[400px]',
          'lg:justify-end lg:order-3 lg:pb-0 lg:gap-md lg:w-[400px]',
        ]"
      >
        <BasicLink
          link="/agb"
          :label="t('subView.footer.agb')"
          target="_blank"
        />
        <BasicLink
          link="/datenschutz"
          :label="t('subView.footer.privacy')"
          target="_blank"
        />
        <BasicLink
          link="/impressum"
          :label="t('subView.footer.impressum')"
          target="_blank"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { EPosition } from '~/@types/basic-button';
import BasicLink from '@/components/components/core/basicLink/basic-link.vue';

const { t } = useTrans();
</script>
