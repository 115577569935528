<template>
  <div class="w-full bg-vdv-bg-base shrink-0">
    <div
      class="grid max-w-full mx-auto touch:md:flex lg:flex md:max-w-screen-lg touch:md:justify-around lg:justify-around sm:grid sm:grid-cols-2 gap-sm px-md md:px-sm py-sm"
    >
      <div
        v-for="(benefit, index) in itemsByLanguage[$languageCode]"
        :key="index"
        class="flex flex-row items-center text-lg font-pero"
      >
        <FaIcon
          :icon="benefit.icon"
          classes="fa-lg mr-xs fa-fw text-minilu-base"
        />
        <p class="text-lg font-pero">{{ t(benefit.title) }}</p>
      </div>
    </div>
    <div class="w-full h-[8px] bg-minilu-base-lighter" />
  </div>
</template>

<script setup lang="ts">
import type { IconData } from '~~/src/server/transformers/iconTransformer';
import FaIcon from '@/components/fa-icon.vue';

const { t } = useTrans();

interface footerItem {
  id: string;
  title: string;
  icon: IconData;
}

const footerItems: Record<string, footerItem> = {
  articles: {
    id: '1011',
    title: 'checkoutSubView.footer.usp.articles',
    icon: {
      iconSet: 'fal-icons',
      label: 'tooth',
    },
  },
  shipment: {
    id: '1022',
    title: 'checkoutSubView.footer.usp.shipment',
    icon: {
      iconSet: 'fal-icons',
      label: 'truck-fast',
    },
  },
  payment: {
    id: '1033',
    title: 'checkoutSubView.footer.usp.payment',
    icon: {
      iconSet: 'fal-icons',
      label: 'credit-card',
    },
  },
  retour: {
    id: '1044',
    title: 'checkoutSubView.footer.usp.retour',
    icon: {
      iconSet: 'fal-icons',
      label: 'arrow-rotate-left',
    },
  },
};

const itemsByLanguage: Record<string, footerItem[]> = {
  de: [
    footerItems.articles,
    footerItems.shipment,
    footerItems.payment,
    footerItems.retour,
  ],
  at: [
    footerItems.articles,
    footerItems.shipment,
    footerItems.payment,
    footerItems.retour,
  ],
  nl: [
    footerItems.articles,
    footerItems.shipment,
    footerItems.payment,
    footerItems.retour,
  ],
};
</script>
